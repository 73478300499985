<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-container>
            <v-row justify="space-around" style="word-break: break-word">
              <v-col md="6"  class="text-justify text-center" align-self="center">
                <div class="text-h4 text-center mb-8">Aplikacja mobilna systemu Urbanitus – Polityka Prywatności</div>
                Niniejsza Polityka Prywatności informuje o zasadach przetwarzania danych związanych z instalacją i korzystaniem z aplikacji mobilnej „Urbanitus” na urządzeniach mobilnych wyposażonych w systemy Android oraz iOS.


                <div :class="captionStyle">Definicje</div>
                <b>Dane lokalizacyjne</b> – Dane umożliwiające precyzyjne określenie pozycji użytkownika poprzez wykorzystanie GPS, czujnika żyroskopowego, akcelerometru oraz kompasu.<br /><br />
                <b>Identyfikator urządzenia</b> – Anonimowa wartość umożliwiająca jednoznaczną identyfikację użytkownika aplikacji mobilnej.<br /><br />
                <b>Użytkownik końcowy</b> – Użytkownik urządzenia mobilnego, który dokonał instalacji aplikacji mobilnej Urbanitus dostępnej dla systemów Android oraz iOS.<br /><br />

                <div :class="captionStyle">Zakres zbieranych informacji</div>

                Informacje, które pozyskujemy z Twojego urządzenia powiązane są ze stanem funkcjonowania (trybem działania) aplikacji Urbanitus.

                W trybie nieaktywnej ochrony uczestnika ruchu aplikacja nie przetwarza, nie kolekcjonuje oraz nie przesyła żadnych danych użytkownika.

                W trybie aktywnej ochrony aplikacja Urbanitus może przetwarzać dane:


                <ol>
                  <li>
                    Lokalizacyjne, umożliwiające precyzyjne określenie pozycji użytkownika,
                  </li>
                  <li>
                    Dane umożliwiające pozyskanie unikalnego identyfikatora urządzenia,
                  </li>
                  <li>
                    Dane umożliwiające wyszukiwanie kompatybilnych urządzeń dostępnych w sieci BLE.
                  </li>
                </ol>

                <br />
                Włączenie trybu ochrony może nastąpić po wybraniu opcji „Aktywuj ochronę” lub w sposób zautomatyzowany, bazując na skonfigurowanym przez użytkownika końcowego harmonogramie działania.


                <div :class="captionStyle">Cel zbieranych informacji</div>
                Celem kolekcjonowanych danych jest:
                <ul>
                  <li>
                    ocena ryzyka wystąpienia zdarzenia drogowego poprzez analizę sposobu przemieszczania się użytkownika aplikacji z wykorzystaniem czujników wbudowanych w urządzenie mobilne,- możliwość manualnego wprowadzania sugestii lub ostrzeżeń dla wskazanych i pokonanych odcinków tras,
                  </li>
                  <li>
                    emisja informacji o ryzyku znalezienia się w strefie ryzyka w celu kolekcjonowania danych statystycznych, które mogą zostać poddane analizie przez jednostki uprawnione (np. zarząd dróg, władze miasta),
                  </li>
                  <li>
                    możliwość analizy danych statystycznych w celu poprawy bezpieczeństwa uczestników ruchu.
                  </li>
                </ul>

                <div :class="captionStyle">Administrator danych osobowych</div>
                Administratorem danych osobowych jest Platforma Biznesu sp. z o.o. z siedzibą w Szczecinie przy ul.
                Królowej Korony Polskiej 2/2, KRS: 0000404732, NIP 851-31-61-089, REGON 321166829.


                <div :class="captionStyle">Zmiany Polityki Prywatności</div>
                Wszelkie zmiany Polityki Prywatności będą publikowane na stronie
                <a href="https://platformabiznesu.net/products/urbanitus/privacy">https://platformabiznesu.net/products/urbanitus/privacy</a>

              </v-col>
            </v-row>

          </v-container>


        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {

  data() {
    return {
      captionStyle: "text-h6 mt-8 mb-3"
    }
  },
}

</script>
